import './App.css';
import IndexRoutes from './routes/IndexRoutes.jsx';

function App()
{
  return (
      <main className={"h-full w-full"} id="scroll-container">
        <IndexRoutes />
      </main>
  );
}

export default App;
