export const required = "Required";

export const emailNotValid = "Email isn't valid";

export const passwordMin = "Password must be at least 8 characters";

export const messageMax = "Message cannot exceed 1000 characters"

export const phoneNotValid = 'Phone number is not valid'

