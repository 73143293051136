

export const mediaItemInputsData = [
    {
        control: "input",
        type: "text",
        name: "title",
        placeholder: "Media Link",
        className: 'block md:w-full w-[100%] ',
        containerClassName: 'block w-full'
    },
]
export const mediaTitleInput = [
    {
        control: "input",
        type: "text",
        name: "title",
        placeholder: "Media Header",
        className: 'block md:w-full w-[100%] ',
        containerClassName: 'block w-full'
    },
]