

import Guest from './Guest'
import Admin from './Admin'

const IndexRoutes = () =>
{

    return (
        <Guest />
    )
}

export default IndexRoutes